import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// hooks
import useAboutUsIntroData from 'src/cms/data/aboutUs/useAboutUsIntroData';
// sections
import OurValues from './sections/OurValues';
import WhatWeDo from './sections/WhatWeDo';
import Expertise from './sections/Expertise';
import HowWeWork from './sections/HowWeWork';
import Clients from './sections/Clients';
import { PageProps } from 'gatsby';

const AboutUs: FC<PageProps> = () => {
  // hooks
  const { title, mobileTitle, description } = useAboutUsIntroData();

  return (
    <PageLayout>
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='aboutUsDrawing'
      />
      <OurValues />
      <WhatWeDo />
      <Expertise />
      <HowWeWork />
      <Clients />
    </PageLayout>
  );
};

export default AboutUs;
