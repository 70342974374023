// hooks
import useAboutUsMetaData from 'src/cms/data/aboutUs/useAboutUsMetaData';
// components
import Seo from 'src/components/Seo';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const aboutUsMetaData = useAboutUsMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={aboutUsMetaData?.title}
      description={aboutUsMetaData?.description}
      schema={aboutUsMetaData?.schema}
    />
  );
};

export { default } from './AboutUs';
